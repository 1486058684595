<script>
/**
 * Auth component
 */
export default {
  data () {
    return {};
  },
};
</script>

<template lang="pug">
div
  .account-pages.mt-5.mb-5
    .container
      slot
    // end container
  // end page
  footer.footer.footer-alt
    | {{ new Date().getFullYear() }} &copy; 開發團隊 <a href="">嵩鴻數位有限公司</a>
</template>
